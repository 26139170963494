import React from 'react';

export default React.memo(() => {
  return (
    <>
      <header>
      </header>
      <main>
      </main>
      <aside>
      </aside>
    </>
  );
})
